import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import HomeIcon from "@mui/icons-material/Home";
import PaidIcon from "@mui/icons-material/Paid";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import { Heading1, Heading2 } from "../../components/heading/Heading";
import EditIcon from "@mui/icons-material/Edit";
import {
  LoaderButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/buttons/Button";
import { GET, POST } from "../../constant/RequestAuthService";
import { useDispatch, useSelector } from "react-redux";
import success from "../../assets/images/success.gif";
import "./Stepper.css";
import { useNavigate } from "react-router-dom";
import AddressModal from "./AddressModal";
import SavedData from "../../redux/api/cart/SavedData";
import { UpdateCartApi } from "../home/topOffers/AddCartFunction";
import { toast } from "react-toastify";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <HomeIcon />,
    2: <PaidIcon />,
    3: <ShoppingBagIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Select Address", "Payment Methods", "Order Placed"];

const OrderStepper = () => {
  const [skipped, setSkipped] = React.useState(new Set());
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [fetch, setFetch] = React.useState(false);
  const [addressId, setAddressId] = React.useState("");
  let [paymentId, setPaymentId] = React.useState("");
  const [cartItems, setCartItems] = React.useState([]);
  const [itemsToSend, setItemsToSend] = React.useState({
    product_id: 0,
    total_items: 0,
    total_price: 0,
  });
  const [checkedTrue, setCheckedTrue] = React.useState(true);
  const [counterTrue, setCounterTrue] = React.useState(true);
  const [placeOrder, setPlaceOrder] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [instructions, setinstructions] = useState("");

  const cartItemSend = useSelector((state) => state.cartsReducer);

  useEffect(() => {
    setCartItems(cartItemSend.items);
  }, [cartItemSend]);

  useEffect(() => {
    setItemsToSend(
      cartItems.map((data) => ({
        product_id: data.id,
        total_items: data.finalQuantity,
        total_price: data.BillPrice * data.finalQuantity,
      }))
    );
  }, [cartItems]);

  // console.log(cartItems);

  //
  //POST ORDER
  //

  const handlePlaceOrder = async () => {
    if (counterTrue === true) {
      let orderToSend = {
        address_id: addressId,
        payment_type_id: paymentId[0],
        total_items: cartItemSend.items.reduce(
          (total, item) => total + item.finalQuantity,
          0
        ),

        total_price: cartItemSend.total_price,
        total_bill_price: cartItemSend.total_bill_price,
        checkout_from: 2,
        cart: itemsToSend,
      };

      if (instructions !== "") {
        orderToSend.instructions = instructions;
      }

      const response = await POST("orders/add", orderToSend);
      setLoading(true);
      if (response.status === 200) {
        dispatch({ type: "PlacedSuccessfully" });
        setPlaceOrder(true);
        setLoading(false);
        setFetch("fetch");
        setTimeout(() => {
          setFetch("");
        }, 300);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      toast.error("Please select any one method");
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    placeOrder === true && handleOrder();
  }, [placeOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOrder = async () => {
    const response1 = await POST("orders/userwise");
    if (response1.status === 200) {
      // console.log(response1.data);
      dispatch({ type: "SavedOrder", payload: response1.data });
    }
  };

  useEffect(() => {
    fetch === "fetch" && UpdateCartApi(cartItemSend);
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (!savedAddress.addresses || savedAddress.addresses.address === "") {
      toast.error("You dont have a valid Address");
    } else {
      if (checkedTrue === true) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      } else {
        toast.error("Please Select any Address");
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const payments = useSelector((state) => state.apiReducer.paymentMethod);

  useEffect(() => {
    setPaymentId(payments.map((data) => data.id));
  }, [fetch]); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();

  const getPaymentMethods = async () => {
    const res = await GET("orders/methods");
    if (res.status === 200) {
      dispatch({ type: "paymentMethod", payload: res.data });
      setFetch(!fetch);
    }
  };

  // const savedAddress = useSelector((state) => state.userReducer.userAddress);
  const savedAddress = useSelector(
    (state) => state.userReducer.userProfile.data
  );
  // console.log(savedAddress);

  useEffect(() => {
    let address = savedAddress.addresses;
    if (savedAddress?.addresses) {
      setAddressId(address.id);
    }
  }, [savedAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getPaymentMethods();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(paymentId);

  const navigate = useNavigate();

  const handleChange = (event) => {
    setCheckedTrue(event.target.checked);
  };
  const handleChange1 = (event) => {
    setCounterTrue(event.target.checked);
  };

  // console.log(placeOrder);

  return (
    <>
      <div style={{ minHeight: "100vh" }} className="d-flex flex-column">
        <SavedData fetch={fetch} setFetch={setFetch} />

        <div style={{ flexGrow: 1 }} className="container">
          <Stepper
            sx={{
              display: "flex",
              flexWrap: "wrap",
              margin: "50px 0px 40px 0px",
            }}
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === steps.length ? (
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {cartItemSend.items.length === 0 ? (
                <>
                  {/* <h1>No Items in the Cart</h1> */}
                  {placeOrder === true ? (
                    <>
                      <div className="success_div p-3 mx-auto mb-5">
                        <img
                          src={success}
                          alt="success"
                          className="mb-5"
                          style={{ width: "6rem" }}
                        />
                        <Heading2
                          title={"Your order has been placed"}
                        ></Heading2>
                        <PrimaryButton
                          onClick={() => navigate("/")}
                          title="Continue Shopping"
                          classNames="mt-4"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ minHeight: "200px" }}
                      >
                        <Heading2 title={"No Items in the Cart"}></Heading2>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div>
                    {activeStep === 0 && (
                      <div className="container">
                        <Heading1 title="My Address" />
                        <div className="row">
                          <div className="col-md-6 col-xl-4 ">
                            <div className="order_main_div mt-4 d-flex align-items-center">
                              {savedAddress?.addresses ? (
                                <>
                                  <Checkbox
                                    checked={checkedTrue}
                                    onChange={handleChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                    sx={{
                                      color: `var(--secondary)`,
                                      "&.Mui-checked": {
                                        color: `var(--secondary)`,
                                      },
                                    }}
                                  />
                                  <HomeIcon
                                    sx={{
                                      color: `var(--primary)`,
                                      marginRight: "10px",
                                    }}
                                  />
                                  <div>
                                    <Heading2 title={savedAddress.name} />
                                    <p className="order_item_Sec_head mt-1">
                                      {savedAddress.addresses.address},{" "}
                                      {savedAddress.addresses.area}
                                    </p>
                                    <p className="order_item_Sec_head mt-1">
                                      {savedAddress.addresses.cityName} ,{" "}
                                      {savedAddress.addresses.stateCode},{" "}
                                      {savedAddress.addresses.pincode}
                                    </p>
                                  </div>
                                  <IconButton
                                    onClick={() => setOpen(true)}
                                    sx={{
                                      marginLeft: "auto",
                                      color: `var(--primary)`,
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </>
                              ) : (
                                <>
                                  <HomeIcon
                                    sx={{
                                      color: `var(--primary)`,
                                      marginRight: "10px",
                                    }}
                                  />{" "}
                                  <Heading2 title="Add Address" />{" "}
                                  <IconButton
                                    onClick={() => setOpen(true)}
                                    sx={{
                                      marginLeft: "auto",
                                      color: `var(--primary)`,
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <div className="container">
                        <Heading1 title="Payment Methods" />
                        <div className="row">
                          <div className="col-md-6 col-xl-4 ">
                            {payments?.length > 0 ? (
                              payments.map((data, i) => (
                                <div
                                  key={i}
                                  className="order_main_div mt-4 d-flex align-items-center"
                                >
                                  <Checkbox
                                    checked={counterTrue}
                                    onChange={handleChange1}
                                    inputProps={{ "aria-label": "controlled" }}
                                    sx={{
                                      color: `var(--secondary)`,
                                      "&.Mui-checked": {
                                        color: `var(--secondary)`,
                                      },
                                    }}
                                  />

                                  <div>
                                    <Heading2 title={data.method} />
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="order_main_div mt-4 d-flex align-items-center justify-content-center">
                                <CircularProgress color="secondary" />
                              </div>
                            )}
                          </div>{" "}
                          <Heading1
                            title="Instructions for seller"
                            classNames="pt-4"
                          />
                          <div className="">
                            <p className="font-italic mb-4">
                              If you have some information for the seller you
                              can leave them in the box below
                            </p>
                            <textarea
                              value={instructions}
                              onChange={(e) => setinstructions(e.target.value)}
                              name="message"
                              id=""
                              rows="10"
                              placeholder="Put some note about this Order (optional)"
                              className="tb  p-2 login_field"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    )}

                    {activeStep === steps.length - 1 && (
                      <div className="container">
                        <div id="card" className="animated fadeIn">
                          <div id="upper-side">
                            <img src={success} alt="success" width="100px" />

                            <Heading2 title="ORDER PLACED" classNames="mt-3" />
                          </div>
                          <div id="lower-side">
                            <p id="message">
                              Congratulations, your Order has been <br />
                              successfully Placed.
                            </p>
                            <PrimaryButton
                              onClick={() => navigate("/")}
                              classNames="mx-auto mt-4"
                              title="Continue Shopping"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Grid className="d-flex">
                      <Box className="row mt-3 px-2 mb-5">
                        {activeStep !== 0 && activeStep !== 2 ? (
                          <SecondaryButton
                            title="Back"
                            onClick={handleBack}
                            classNames="mt-2 ms-3"
                          />
                        ) : (
                          ""
                        )}

                        {/* <Button
                      color="success"
                      variant="contained"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ marginTop: "20px" }}
                    >
                      <Typography fontSize={14} sx={{ textTransform: "none" }}>
                        Backss
                      </Typography>
                    </Button> */}
                      </Box>
                      {activeStep !== steps.length - 2 &&
                        activeStep !== steps.length - 1 && (
                          <PrimaryButton
                            classNames="ms-4 mt-4 mb-5"
                            onClick={handleNext}
                            title="Next"
                          />
                        )}
                      {activeStep === steps.length - 2 &&
                        (loading === false ? (
                          <PrimaryButton
                            classNames="ms-4 mt-4 mb-5"
                            onClick={handlePlaceOrder}
                            title="PLACE ORDER"
                          />
                        ) : (
                          <div className="mt-4 ms-3">
                            <LoaderButton />
                          </div>
                        ))}
                    </Grid>
                  </div>
                </>
              )}
            </React.Fragment>
          )}
        </div>

        <AddressModal open={open} setOpen={setOpen} handleClose={handleClose} />
      </div>
    </>
  );
};

export default OrderStepper;
