import { createReducer } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  items: [],
  total_cart: 0,
  total_price: 0,
  total_bill_price: 0,
  date: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
  // allProduct: [],
  homeProduct: [],
  resentSearchProduct: [],
  categoryProduct: [],
  getAllvalues: false,
  nextProductLinks: null,
  moreProduct: [],
  subCategoryProducts: [],
  nextSubCategoryProductLinks: null,
  searchProduct: [],
  nextSearchProductLinks: null,
  loading: false,
  moreProductLoad: false,
  reloadLoading: false,
  featuredProduct: [],
  nextFeatureProductLinks: [],
  featuredProducts: [],
};

const cartsReducer = createReducer(initialState, {
  // addInstruction: (state, action) => {
  //   state.Instructions = action.payload;
  // },
  loadingtrue: (state) => {
    state.loading = true;
  },
  loadingfalse: (state) => {
    state.loading = false;
  },
  LoadingStartProduct: (state) => {
    state.moreProductLoad = true;
  },
  LoadingStopProduct: (state) => {
    state.moreProductLoad = false;
  },
  ReloadLoadingStart: (state) => {
    state.reloadLoading = true;
  },
  ReloadLoadingStop: (state) => {
    state.reloadLoading = false;
  },
  IsResentSearch: (state, action) => {
    // console.log(action.payload);
    state.resentSearchProduct = action.payload;
  },
  IsProductAdded: (state) => {
    const catproduct = [...state.categoryProduct];
    const homeProduct = [...state.homeProduct];
    const featuredproduct = [...state.featuredProduct];
    const featuredproducts = [...state.featuredProducts];
    const moreProduct = [...state.moreProduct];
    const subcategoryProducts = [...state.subCategoryProducts];
    const searchProduct = [...state.searchProduct];

    const checkedArray2 = catproduct.map((x) => {
      const valuesChangeCheck = state.items.filter((y) => y.id === x.id);

      if (valuesChangeCheck.length > 0) {
        x.finalQuantity = valuesChangeCheck[0].finalQuantity;
      }
      return x;
    });

    const checkedArray3 = homeProduct.map((x) => {
      const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
      if (valuesChangeCheck.length > 0) {
        x.finalQuantity = valuesChangeCheck[0].finalQuantity;
      }
      return x;
    });

    const checkedArray4 = moreProduct.map((x) => {
      const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
      if (valuesChangeCheck.length > 0) {
        x.finalQuantity = valuesChangeCheck[0].finalQuantity;
      }
      return x;
    });

    const checkedArray5 = subcategoryProducts.map((x) => {
      const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
      if (valuesChangeCheck.length > 0) {
        x.finalQuantity = valuesChangeCheck[0].finalQuantity;
      }
      return x;
    });

    const checkedArray6 = searchProduct.map((x) => {
      const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
      if (valuesChangeCheck.length > 0) {
        x.finalQuantity = valuesChangeCheck[0].finalQuantity;
      }
      return x;
    });

    const checkedArray7 = featuredproduct.map((x) => {
      const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
      if (valuesChangeCheck.length > 0) {
        x.finalQuantity = valuesChangeCheck[0].finalQuantity;
      }
      return x;
    });
    const checkedArray8 = featuredproducts.map((x) => {
      const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
      if (valuesChangeCheck.length > 0) {
        x.finalQuantity = valuesChangeCheck[0].finalQuantity;
      }
      return x;
    });

    state.categoryProduct = checkedArray2;
    state.homeProduct = checkedArray3;
    state.moreProduct = checkedArray4;
    state.subCategoryProducts = checkedArray5;
    state.searchProduct = checkedArray6;
    state.featuredProduct = checkedArray7;
    state.featuredProducts = checkedArray8;
  },

  CategoryProduct: (state, action) => {
    const fetchArray = action.payload.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.categoryProduct = fetchArray;

    if (state.categoryProduct?.length) {
      const newAllProduct = [...state.categoryProduct];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.categoryProduct = checkedArray;
    }
  },
  NoCategoryProduct: (state, action) => {
    state.categoryProduct = [];
  },

  AddItems: (state, action) => {
    if (!state.items.includes(action.payload)) {
      state.items = [
        ...state.items,
        ...[
          {
            finalQuantity: action.payload.finalQuantity,

            picture: action.payload.picture || null,
            name: action.payload.name
              ? action.payload.name
              : action.payload.product.name,
            id: action.payload.id,
            Mrp: action.payload.Mrp,
            total_qty: parseInt(action.payload.total_qty),
            BillPrice: action.payload.BillPrice,
          },
        ],
      ];
      toast.success("Cart Added Successfully", { position: "bottom-left" });
      state.total_cart = state.items.length;
      state.total_price = state.items.reduce(
        (total, item) => total + item.Mrp * item.finalQuantity,
        0
      );
      state.total_bill_price = state.items.reduce(
        (total, item) => total + item.BillPrice * item.finalQuantity,
        0
      );
    }
  },
  AddItemsFromDetailsPage: (state, action) => {
    if (!state.items.includes(action.payload)) {
      state.items = [
        ...state.items,
        ...[
          {
            finalQuantity: action.payload.finalQuantity,

            picture: action.payload?.picture?.length || null,
            name: action.payload.name,
            id: action.payload.id,
            Mrp: action.payload?.mapped?.pos_product?.Mrp,
            total_qty: action.payload?.mapped?.pos_product?.total_qty,
            BillPrice: action.payload?.mapped?.pos_product?.BillPrice,
          },
        ],
      ];
      toast.success("Cart Added Successfully", { position: "bottom-left" });
      state.total_cart = state.items.length;
      state.total_price = state.items.reduce(
        (total, item) => total + item.Mrp * item.finalQuantity,
        0
      );
      state.total_bill_price = state.items.reduce(
        (total, item) => total + item.BillPrice * item.finalQuantity,
        0
      );
    }
  },

  AddItemsfromCategory: (state, action) => {
    if (!state.items.includes(action.payload)) {
      state.items = [
        ...state.items,
        ...[
          {
            finalQuantity: action.payload.finalQuantity,
            pos_product: action.payload.mapped.pos_product,
            picture: action.payload.picture,
            name: action.payload.name,
            id: action.payload.id,
          },
        ],
      ];
      toast.success("Cart Added Successfully", { position: "bottom-left" });
      state.total_cart = state.items.length;
      state.total_price = state.items.reduce(
        (total, item) => total + item.pos_product.Mrp * item.finalQuantity,
        0
      );
      state.total_bill_price = state.items.reduce(
        (total, item) => total + item.BillPrice * item.finalQuantity,
        0
      );
    }
  },
  DeleteItems: (state, action) => {
    const newCart = state.items.filter(
      (product) => product.id !== action.payload
    );

    toast.error("Cart Removed !", { position: "bottom-left" });
    state.items = newCart;
    state.total_cart = state.items.length;
    state.total_price = state.items.reduce(
      (total, item) => total + item.price * item.finalQuantity,
      0
    );
    state.total_bill_price = state.items.reduce(
      (total, item) => total + item.BillPrice * item.finalQuantity,
      0
    );
  },
  SavedData: (state, action) => {
    state.items = action.payload.items;
    state.total_cart = action.payload.totalCart;
    state.total_price = action.payload.totalPrice;
    state.total_bill_price = action.payload.total_bill_price;
    state.getAllvalues = true;
  },
  UpdateData: (state, action) => {
    //For items in our cart
    const element = state.items.findIndex(
      (elem) => elem.id === action.payload.id
    );
    const changedCart = [...state.items];

    if (
      changedCart[element].finalQuantity < action.payload?.total_qty
      // ? action.payload.mapped.pos_product.total_qty
      // : action.payload.pos_product.total_qty
    ) {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity + 1,
      };
    } else {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity,
      };

      toast.error(`Available Quanity is ${action.payload.total_qty},`, {
        position: "bottom-left",
        theme: "colored",
      });
    }

    state.items = changedCart;
    state.total_price = state.items.reduce(
      (total, item) => total + item.Mrp * item.finalQuantity,
      0
    );
    state.total_bill_price = state.items.reduce(
      (total, item) => total + item.BillPrice * item.finalQuantity,
      0
    );
    state.total_cart = state.items.length;
  },
  UpdateDataFromHome: (state, action) => {
    //For items in our cart
    const element = state.items.findIndex(
      (elem) => elem.id === action.payload.id
    );
    const changedCart = [...state.items];

    if (
      changedCart[element].finalQuantity < action.payload.pos_product.total_qty
    ) {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity + 1,
      };
    } else {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity,
      };

      toast.error(
        `Available Quanity is ${action.payload.pos_product.total_qty}`
      );
    }

    state.items = changedCart;
    state.total_price = state.items.reduce(
      (total, item) => total + item.pos_product.Mrp * item.finalQuantity,
      0
    );
    state.total_cart = state.items.length;
  },
  UpdateDataFromCategoryPage: (state, action) => {
    //For items in our cart
    const element = state.items.findIndex(
      (elem) => elem.id === action.payload.id
    );
    const changedCart = state.items;

    if (
      changedCart[element].finalQuantity <
      action.payload.mapped.pos_product.total_qty
    ) {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity + 1,
      };
    } else {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity,
      };

      toast.error(
        `Available Quanity is ${action.payload.mapped.pos_product.total_qty}`
      );
    }

    state.items = changedCart;
    state.total_price = state.items.reduce(
      (total, item) => total + item.pos_product.Mrp * item.finalQuantity,
      0
    );
    state.total_cart = state.items.length;
  },
  UpdateDataFromDetailsPage: (state, action) => {
    //For items in our cart
    const element = state.items.findIndex(
      (elem) => elem.id === action.payload.id
    );
    // console.log(element);
    const changedCart = [...state.items];

    if (
      changedCart[element].finalQuantity < action.payload.pos_product.total_qty
    ) {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity + 1,
      };
    } else {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity,
      };

      toast.error(
        `Available Quanity is ${action.payload.pos_product.total_qty}`
      );
    }

    state.items = changedCart;
    state.total_price = state.items.reduce(
      (total, item) => total + item.pos_product.Mrp * item.finalQuantity,
      0
    );
    state.total_cart = state.items.length;
  },
  UpdateDataFromCategory: (state, action) => {
    //For items in our cart
    const element = state.items.findIndex(
      (elem) => elem.id === action.payload.id
    );

    const changedCart = [...state.items];

    // console.log(particularElem);
    if (
      changedCart[element].finalQuantity <
      action.payload.mapped.pos_product.total_qty
    ) {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity + 1,
      };
    } else {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity,
      };

      toast.error(
        `Available Quanity is ${action.payload.mapped.pos_product.total_qty}`
      );
    }

    // console.table(changedProduct);
    state.items = changedCart;
    state.total_price = state.items.reduce(
      (total, item) => total + item.pos_product.Mrp * item.finalQuantity,
      0
    );
    state.total_cart = state.items.length;
  },

  DegradeData: (state, action) => {
    const element = state.items.findIndex((elem) => elem.id === action.payload);
    const changedCart = [...state.items];

    if (changedCart[element].finalQuantity > 1) {
      changedCart[element] = {
        ...changedCart[element],
        finalQuantity: changedCart[element].finalQuantity - 1,
      };
    }

    state.items = changedCart;
    state.total_price = state.items.reduce(
      (total, item) => total + item.Mrp * item.finalQuantity,
      0
    );
    state.total_bill_price = state.items.reduce(
      (total, item) => total + item.BillPrice * item.finalQuantity,
      0
    );
    state.total_cart = state.items.length;
  },

  getHomeProduct: (state, action) => {
    const fetchArray = action.payload.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.homeProduct = fetchArray;

    if (state.homeProduct?.length) {
      const newAllProduct = [...state.homeProduct];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.homeProduct = checkedArray;
    } else {
      // console.log("not comes array");
    }
    // state.nextProductLinks = action.payload.next_page_url;
  },
  getFeaturedProduct: (state, action) => {
    const fetchArray = action.payload.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.featuredProduct = fetchArray;

    if (state.featuredProduct?.length) {
      const newAllProduct = [...state.featuredProduct];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.featuredProduct = checkedArray;
    } else {
      // console.log("not comes array");
    }
  },
  getMoreFeaturedProduct: (state, action) => {
    const fetchArray = action.payload.data.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.featuredProducts = fetchArray;

    if (state.featuredProducts?.length) {
      const newAllProduct = [...state.featuredProducts];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.featuredProducts = checkedArray;
    } else {
      // console.log("not comes array");
    }
    state.nextFeatureProductLinks = action.payload?.next_page_url;
  },
  getMoreProduct: (state, action) => {
    const fetchArray = action.payload.data.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.moreProduct = fetchArray;

    if (state.moreProduct?.length) {
      const newAllProduct = [...state.moreProduct];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.moreProduct = checkedArray;
      state.nextProductLinks = action.payload.next_page_url;
    } else {
      // console.log("not comes array");
    }
  },
  getSearchProduct: (state, action) => {
    const fetchArray = action.payload.data.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.searchProduct = fetchArray;

    if (state.searchProduct?.length) {
      const newAllProduct = [...state.searchProduct];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.searchProduct = checkedArray;
      state.nextSearchProductLinks = action.payload.next_page_url;
    } else {
      // console.log("not comes array");
    }
  },
  emptySearchProduct: (state) => {
    state.searchProduct = [];
  },
  emptySearchData: (state) => {
    state.searchProduct = [];
  },
  subCategoryProducts: (state, action) => {
    const fetchArray = action.payload.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.subCategoryProducts = fetchArray;

    if (state.subCategoryProducts?.length) {
      const newAllProduct = [...state.subCategoryProducts];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.subCategoryProducts = checkedArray;
      state.nextSubCategoryProductLinks = action.payload.next_page_url;
    }
  },

  AddProductinallProduct: (state, action) => {
    const fetchArray = action.payload.data.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.moreProduct = [...state.moreProduct, ...fetchArray];

    if (state.moreProduct?.length) {
      const newAllProduct = [...state.moreProduct];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.moreProduct = checkedArray;
    } else {
      // console.log("not comes array");
    }
    state.nextProductLinks = action.payload.next_page_url;
  },
  AddProductinFeatureProduct: (state, action) => {
    const fetchArray = action.payload.data.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.featuredProduct = [...state.featuredProduct, ...fetchArray];

    if (state.featuredProduct?.length) {
      const newAllProduct = [...state.featuredProduct];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.featuredProduct = checkedArray;
    } else {
      // console.log("not comes array");
    }
    state.nextFeatureProductLinks = action.payload.next_page_url;
  },
  AddProductinSearchProduct: (state, action) => {
    const fetchArray = action.payload.data.map((v) => ({
      ...v,
      finalQuantity: 1,
    }));
    state.searchProduct = [...state.searchProduct, ...fetchArray];

    if (state.searchProduct?.length) {
      const newAllProduct = [...state.searchProduct];

      const checkedArray = newAllProduct.map((x) => {
        const valuesChangeCheck = state.items.filter((y) => y.id === x.id);
        if (valuesChangeCheck.length > 0) {
          // console.log("valuesChangeCheck", valuesChangeCheck);
          // console.log("hy");
          x.finalQuantity = valuesChangeCheck[0].finalQuantity;
        }
        return x;
      });

      state.searchProduct = checkedArray;
    } else {
      // console.log("not comes array");
    }
    state.nextProductLinks = action.payload.next_page_url;
  },

  PlacedSuccessfully: (state) => {
    state.items = [];
    state.total_cart = 0;
    state.total_price = 0;
  },
});

export default cartsReducer;
