import { Backdrop, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Heading1, Heading2 } from "../../../components/heading/Heading";
import SearchIcon from "@mui/icons-material/Search";
import "./MyOrder.scss";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { POST, PUT } from "../../../constant/RequestAuthService";
import { useDispatch, useSelector } from "react-redux";
import { Img_url } from "../../../constant";
import {
  PrimaryButton,
  SecondaryButton,
} from "../../../components/buttons/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import Validator from "validatorjs";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState("");
  const [del, setDel] = useState("");
  const [comment, setComment] = useState("");
  const [input, setInput] = useState("");
  const [orderDetail, setOrderDetail] = useState([]);

  useEffect(() => {
    if (detail.length !== 0) {
      if (input.length !== 0) {
        const filtArr = detail.order_details.filter(
          (x) =>
            x.product.name.toLowerCase().includes(input.toLowerCase()) ||
            x.total_price.includes(input)
        );

        setOrderDetail(filtArr);
      } else {
        const filtArr = detail.order_details.filter(
          (x) =>
            x.product.name.toLowerCase().includes(input.toLowerCase()) ||
            x.total_price.includes("")
        );
        // console.log(detail);
        setOrderDetail(filtArr);
      }
    }
  }, [input]); // eslint-disable-line react-hooks/exhaustive-deps

  const myOrders = useSelector((state) => state.userReducer.orders);

  useEffect(() => {
    setOrders(myOrders);
  }, [myOrders]);

  // console.log(myOrders);

  const orderStatus = useSelector((state) => state.userReducer.filterOrders);
  // console.log(orderStatus);

  useEffect(() => {
    if (orderStatus && orderStatus.length !== 0) {
      // console.log(orderStatus);
      setOrders(myOrders.filter((data) => data.status_id === orderStatus.id));
    }
  }, [orderStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOrder = async () => {
    const response1 = await POST("orders/userwise");

    if (response1.status === 200) {
      // console.log(response1.data);
      dispatch({ type: "SavedOrder", payload: response1.data });
    }
  };

  useEffect(() => {
    !myOrders && handleOrder();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useDispatch();

  const isFrontImages = JSON.parse(localStorage.getItem("frontImages"));

  const matches = useMediaQuery("(min-width:660px)");

  // console.log(orders);

  const handelDetail = (data) => {
    setDetail(data);
    setOrderDetail(data.order_details);
    setOpen(true);
  };
  // console.log(orders);

  const Delete = async (data) => {
    setLoading(data.id);

    const requestData = {
      comment: comment,
      id: data.id,
    };

    const checkdata = {
      comment: "required|min:20",
      id: "required",
    };

    const validation = new Validator(requestData, checkdata);

    if (validation.fails()) {
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading("");
    } else {
      const response = await PUT("orders/cancel", requestData);
      if (response.status === 200) {
        toast.error(response.message);
        handleOrder();
        setDel("");
        setComment("");
        setLoading("");
      } else {
        toast.error(response.message);
        setLoading("");
      }
    }
  };

  const OpenComm = (data) => {
    setDel(data.id);
  };

  const Back = () => {
    setOpen(false);
    setDetail([]);
    setInput("");
  };

  // console.log(orders);

  return (
    <>
      <div className="container">
        <div className="border_bottom_with_padding_in_product pb-4 ">
          <Heading1 title="My Orders" classNames="mt-2" />
        </div>
        <div className="row ">
          <div className="col-12 mt-2">
            {orders && typeof orders === "object" ? (
              <>
                {orders.length === 0 ? (
                  <div className="d-flex align-items-center justify-content-center h-100 mt-5">
                    <Heading2 title={"No Orders Yet"}></Heading2>
                  </div>
                ) : (
                  <>
                    {open === false ? (
                      <>
                        {orders.map((item, i) => (
                          <div key={i}>
                            {del === item.id ? (
                              <div className="order_main_div  p-0 mt-3">
                                <div className="px-3">
                                  <Heading2
                                    title="Reason for Cancellation *"
                                    classNames="pt-3"
                                  />
                                  <textarea
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    name="message"
                                    id=""
                                    rows="1"
                                    placeholder="Comment..."
                                    className="tb mt-2 login_field w-100 mb-2"
                                  ></textarea>
                                  <div className="d-flex align-items-center mb-3">
                                    <div className="me-2">
                                      {loading === item.id ? (
                                        <LoadingButton loading />
                                      ) : (
                                        <SecondaryButton
                                          title="Cancel Order"
                                          onClick={() => Delete(item)}
                                        />
                                      )}
                                    </div>

                                    <PrimaryButton
                                      title="Back"
                                      onClick={() => setDel("")}
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="order_main_div order_list p-0 mt-3">
                                <div className="p-2">
                                  <p className="order_item_main_head">
                                    Total Items : {item.total_items}
                                  </p>
                                  {/* <p className="order_item_Sec_head mt-1">
                                  {item.product.para}
                                </p> */}
                                </div>
                                <div className="p-2">
                                  <p className="order_item_main_head">
                                    Total Price :
                                  </p>
                                  <p className="order_item_Sec_head">
                                    Rs {item.total_bill_price}
                                  </p>
                                </div>
                                <div className="p-2">
                                  <div className="d-flex align-items-center">
                                    <>
                                      {item.delivered && (
                                        <FiberManualRecordIcon
                                          sx={{
                                            color: `var(--primary) `,
                                            width: "15px",
                                            paddingTop: "2px",
                                          }}
                                        />
                                      )}
                                      {item.cancelled && (
                                        <FiberManualRecordIcon
                                          sx={{
                                            color: `var(--secondary) `,
                                            width: "15px",
                                            paddingTop: "2px",
                                          }}
                                        />
                                      )}
                                      <FiberManualRecordIcon
                                        sx={{
                                          color: `${
                                            item.status.status === "Pending"
                                              ? "orange"
                                              : item.status.status ===
                                                "Completed"
                                              ? "green"
                                              : item.status.status ===
                                                "Approved"
                                              ? "blue"
                                              : item.status.status === "Return"
                                              ? "red"
                                              : " "
                                          }`,

                                          width: "15px",
                                          paddingTop: "2px",
                                        }}
                                      />

                                      <p className="order_item_main_head">
                                        &nbsp; {item.delivered && "Deliverd"}
                                        {item.cancelled && "Cancelled"}
                                        {item.Refund && "Refund"} On{" "}
                                        {item.created_at}
                                      </p>
                                    </>
                                  </div>
                                  <div
                                    style={{
                                      color: `${
                                        item.status.status === "Pending"
                                          ? "orange"
                                          : item.status.status === "Completed"
                                          ? "green"
                                          : item.status.status === "Approved"
                                          ? "blue"
                                          : item.status.status === "Return"
                                          ? "red"
                                          : " "
                                      }`,
                                    }}
                                    className="order_item_Sec_head mt-1 d-flex align-items-center fw_700"
                                  >
                                    {item.status.status}
                                  </div>
                                </div>
                                <div className="p-2 ">
                                  <div
                                    style={{ zIndex: 999999 }}
                                    className="d-flex align-items-center order_delete_div"
                                  >
                                    {item.status_id === 4 ? (
                                      ""
                                    ) : matches ? (
                                      <SecondaryButton
                                        title="Cancel"
                                        classNames="me-2"
                                        onClick={() => OpenComm(item)}
                                      />
                                    ) : (
                                      <>
                                        <DeleteOutlineIcon
                                          className="d-ico pointer me-2"
                                          onClick={() => OpenComm(item)}
                                        />
                                      </>
                                    )}
                                    <div onClick={() => handelDetail(item)}>
                                      {matches ? (
                                        <PrimaryButton
                                          title="More Details"
                                          // onClick={() => Delete(data)}
                                        />
                                      ) : (
                                        <MoreHorizIcon
                                          sx={{ color: `var(--primary)` }}
                                          className="d-ico pointer"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div
                            style={{ padding: " 0px 20px" }}
                            className=" col-md-6 col-xl-4 "
                          >
                            {" "}
                            <div className="relative mt-4 ">
                              <input
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                style={{ paddingLeft: "15px" }}
                                className="search_input_field"
                                placeholder="Search your Products here"
                              />
                              <IconButton className="icon seacrh_icon_input_inside">
                                <SearchIcon className=" pink_icon" />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex align-items-center justify-content-center">
                          <div
                            style={{ paddingLeft: "20px" }}
                            className=" col-md-6 col-xl-4 "
                          >
                            <SecondaryButton
                              classNames="mt-4"
                              title="Back"
                              onClick={Back}
                            />
                            {detail.comment &&
                              detail.comment !== null &&
                              detail.comment !== "" && (
                                <div className="text-success mt-3">
                                  <div>
                                    Product is : {detail?.status?.status}{" "}
                                  </div>
                                  <div>Reason : {detail.comment}</div>
                                </div>
                              )}
                          </div>
                        </div>

                        {orderDetail.map((data, i) => (
                          <div
                            key={i}
                            className="row d-flex align-items-center justify-content-center"
                          >
                            <div className=" col-md-6 col-xl-4">
                              <div
                                className={`  order_main_div mt-3 mx-2 d-flex align-items-center justify-content-between br_8 p-2`}
                                style={{ gap: "15px", background: "#ebf0f6" }}
                              >
                                <div className="order_img_div d-flex align-items-center">
                                  {data?.product?.picture?.length ? (
                                    <Zoom>
                                      <img
                                        alt="details"
                                        src={`${Img_url}${data.product.picture[0].name}`}
                                        style={{
                                          width: "60px",
                                          height: "40px",
                                        }}
                                      />
                                    </Zoom>
                                  ) : (
                                    <>
                                      {isFrontImages ? (
                                        <Zoom>
                                          <img
                                            alt="details"
                                            src={`${Img_url}${isFrontImages[3].credencial_content}`}
                                            style={{
                                              width: "60px",
                                              height: "40px",
                                            }}
                                          />
                                        </Zoom>
                                      ) : (
                                        <CircularProgress color="secondary" />
                                      )}
                                    </>
                                  )}
                                  <div className="ps-2 pt-0 pb-mxw ">
                                    <p className="order_item_main_head">
                                      {data.product.name}
                                    </p>
                                    <p className="order_item_Sec_head  mt-mxw">
                                      Quanity:{" "}
                                      <span
                                        className="fw_700  "
                                        style={{
                                          color: `var(--primary) !important`,
                                        }}
                                      >
                                        {data.total_items}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <p className="order_item_main_head">
                                  Rs {data.total_price}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="row d-flex align-items-center justify-content-center">
                          <div className=" col-md-6 col-xl-4 ">
                            <div
                              className={`  order_main_div mt-3 mx-2 d-flex align-items-center justify-content-between br_8 p-2`}
                              style={{
                                gap: "15px",
                                background: "var(--primary)",
                              }}
                            >
                              <div className="order_img_div d-flex align-items-center">
                                <div className="p-2 pb-mxw">
                                  <p className=" fw_700 fs_24 text-white">
                                    Total
                                  </p>
                                </div>
                              </div>
                              <p className="order_item_main_head text-white">
                                Rs {detail.total_bill_price}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={true}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrders;
